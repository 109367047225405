// var ABE_Service_URL = "https://abeservice.elasticbeanstalk.com";
//var ABE_Service_URL = "https://mserver.us-east-1.elasticbeanstalk.com"; // New Server URL
var ABE_Service_URL = "https://login.morphient.com"; // New Server URL

// File Configs
var FILE_CONFIG = {
		maxSize : 500000,   // 500 GB
		maxSizeUnit : "MB",
		fileType : ["csv"]
};
var FILE_CHUNK_SIZE = 50*1024*1024; // 50 MB;
var MUPLTIPART_DOWNLOAD_LIMIT = 10*1024*1024; // 10 MB

// Following variables are used for logout functionality
var AMAZON_APP_ID = 'amzn1.application-oa2-client.f94487b957c848ec801326bc4f243886'; // AMAZON APP ID USED IN LOGOUT
var FB_ACCESS_URL = ABE_Service_URL + "/auth/fb";
var FB_LOGOUT_URL = "https://www.facebook.com/logout.php";
var GOOGLE_LOGOUT_URL = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=";

// Local Namespace
AWSClient = {};

module.exports = AWSClient;